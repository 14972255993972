.slider-container {
    position: relative;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
  }
  
  .slider {
    display: flex;
    overflow-x: hidden;
  }
  
  .slide {
    flex: 0 0 auto;
    width: 100%;
    transition: transform 0.5s ease;
  }
  
  .slide img {
    width:400px;
    height: 300px;
    object-fit: cover;
  }
  
  .bullets1 {
    display: flex;
    justify-content: center;
   
  }
  
  .bullet1 {
    cursor: pointer;
    width: 10px;
    height: 10px;
    background-color: #fff9f9;
    border-radius: 50%;
    margin: 0 5px;
  }
  
  .bullet1.active {
    background-color: #333;
  }
  
.slide.active{
    background-color:#e9d1ad;
}

@media screen and (max-width:1700px){
  .slide img {
    width: 800px;
    height: 500px;
    object-fit: cover;
  }
   

  
  
}




@media screen and (max-width:1000px){
  .slide img {
    width: 220px;
    height: 260px;
    object-fit: cover;
  }

  .divcon{
    font-size:10px;
  }
}




@media screen and (max-width:500px) {
  .slide img{
    width:320px;
    height: 200px;

  }
}

@media screen and (max-width:395px) {
  .slide img {
     width: 250px;
     height: 190px;
    
    
    
  }
  
}

@media screen and (max-width:300px){

  .slide img{
    width: 200px;
    height: 125px;
  }
  
}

@media screen and (max-width:200px){

  .slide img{
    width: 170px;
    height: 100px;
  }
  
  
    
  }