.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  margin-top: 10%;
  width: 80%;
  height: 60%;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  position: relative; /* Add position relative */
}

.popup-content .close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
}

.popup-content h2 {
  margin-top: 0;
}

.popup-content p {
  margin-bottom: 0;
}



.sliderp {
  
  display: flex;
  flex-wrap: wrap;
  width: 100%;
 height:max-content;
 
  overflow: hidden;
  animation: slideFromAbove 1s forwards;
  background-image: url("https://res.cloudinary.com/dxoejrovf/image/upload/v1711714849/kashmir-honeymoon-package-1000x1000_vmehn3.png");
  background-size: cover;
  filter: grayscale(10%); /* Apply greyscale filter */
}

  
  /* Rest of your CSS styles */
  
  
  
 
  
 
  
  
  .slides {
    display: flex;
    flex-wrap: wrap; /* Wrap the content blocks */
    justify-content:space-around;
    align-items: center;
    overflow: hidden; /* Remove scrolling */
   
    background-size: cover;
    filter: grayscale(10%);
  }
  
  .slides > div {
    width: calc(30% - 20px); /* Adjusted width with spacing between blocks */
    margin: 10px; /* Add spacing between blocks */
    background: rgb(247, 236, 224);
    transform-origin: center center;
    transform: scale(0.7);
    transition: transform 2s;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    font-size: x-large;
  }
  
  .content {
    font-size: large;
    display: block;
    align-items: center;
    justify-content: center;
    height: 50%;
    margin-bottom: 20px;
  }
  
  

  .slides > div:hover{
    transform: scale(1);
  }
  
  .author-info {
    background: rgba(0, 0, 0, 0.75);
    color: rgb(247,236,224);
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
  }
  
  .author-info a {
    color: rgb(155, 155, 155);
  }
  
  img {
    object-fit: contain;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    
  }
  
  /*.sliderp > a {
    margin-top: 3%;
    margin-left: 25%;
    display: inline-flex;
    width: 0.7%; 
    height: 1vh; 
   
    background: rgb(0, 0, 0);
    align-items: center;
    justify-content: center;
    border-radius: 60%;
    position: relative;
    animation: slideFromLeft 2s forwards;
  }*/

  @keyframes slideFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideFromAbove {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .sliderp > a:hover {
    background: rgb(188, 0, 0);
  }
  
  /* Don't need button navigation */
  @supports (scroll-snap-type) {
    .sliderp > a {
      display: none;
    }
  }

 
  @media (max-width: 700px) {
      
    .slides > div:hover{
      transform: scale(0.8);
    }

   
 
     .sliderp{
      height: 200%;
      display: flex; /* Change display to block for stacking slides vertically */
      flex-wrap: wrap;
     }
 
    .content{
      font-size:medium;
      height: 50%;
    }

    
      .slides > div {
     
        width: 40%; /* Set width to 100% for responsiveness */
        height:150%; /* Allow height to adjust based on content */
        /* Add spacing between slides */
        transform: scale(0.7); /* Reset transform for smaller screens */
        transition: transform 0.5s; /* Adjust transition duration */
      }
  
    .slides {
      display: flex; /* Change display to block for stacking slides vertically */
       flex-wrap: wrap;
      overflow-x: hidden; /* Hide horizontal overflow */
       height: max-content;
       justify-content: space-around;
    }
  }



  @media (max-width: 450px) {
      
    .popup-content{
      margin-top: 20%;
     height: 50%;
      width: 60%;
    }
    
    .popup-content h2 {
      margin-top: 0;
      font-size: medium;
    }
    
    .popup-content p {
      margin-bottom: 0;
      font-size: small;
    }
 
    .content{
       
     
      height: 50%;
      font-size: x-small;
     
    }
    

    .slides > div {
     
      width: 70%; /* Set width to 100% for responsiveness */
      height:100%; /* Allow height to adjust based on content */
      margin-bottom: 5%; /* Add spacing between slides */
      transform: scale(0.7); /* Reset transform for smaller screens */
      transition: transform 0.5s; /* Adjust transition duration */
    }
    .slides > div:hover {
      transform: scale(1);
    
    }

    .slides{
     height: 200%;
     margin-bottom: 100px;
    }

    .sliderp{
     height: 200%;
     
    }
   
  }