.book-room {
  display: flex;
  justify-content:space-evenly;
  align-items:center;
  flex-direction: column; /* Ensure items are stacked vertically */
  font-family: Arial, Helvetica, sans-serif;
  background: rgb(247,236,224);
  
  height: 175vh;
}


.book-room > h1,.book-room > h3
{
  font-size:xx-large;
  animation: slideFromAbove 2s forwards;
 
 
}





.box {
  animation: slideFromLeft 2s forwards;
  display: flex;
  justify-content: space-between;
  width: 70%;
  background-color: white;
  
  align-items: center;
  margin-bottom: 5%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.box img {
  width: 40%; /* Adjust as needed */
  height: auto;
  object-fit: cover;
}

.box h3{
  margin-right: 5%;
  font-size: 100%;
}

.box >div >  p {
  font-size: 125%;
}


.more-details {
  background-color: #a09f9f81;
  font-size:small;
  color: #000000;
  padding: 10px 20px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  cursor: pointer;
}

.more-details:hover {
  background-color: #888888;
}


@keyframes slideFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideFromAbove {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }


/*more details */

  .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    position: absolute;
    top: 20%; /* Adjust as needed */
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    animation:slideFromAbove 1s forwards
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .amenities {
    margin-top: 20px;
  }
  
  .amenities h3 {
    margin-bottom: 10px;
  }
  
  .amenities ul {
    list-style-type: none;
    padding: 0;
  }
  
  .amenities ul li {
    margin-bottom: 5px;
  }
  
  @media screen and (max-width: 850px) {
   
    .book-room > h1,.book-room > h3
{
  font-size:xx-large;
  margin-top: -25%;
  margin-bottom: -10%;
  animation: slideFromAbove 2s forwards;
 
 
}
    .modal {
      width: 60%;
    }

    .box {
      width: 80%; /* Adjust width as needed */
      margin-bottom: -15%;
      margin-top: -15%;
    }
    
    .box img {
      width: 30%; /* Adjust image width as needed */
    }

    .box h3{
      margin-right: 5%;
      font-size: 60%;
    }
    
    .box >div >  p {
      font-size: 95%;
    }
  }


  


  @media screen and (max-width: 700px) {
   
    .book-room > h1,.book-room > h3
    {
      font-size:xx-large;
      margin-top: -20%;
      margin-bottom: -5%;
      animation: slideFromAbove 2s forwards;
     
     
    }

    .box {
        margin-bottom: -5%;
      width: 80%; /* Adjust width as needed */
       height: 12%;
    }
    
    .box img {
      height: 100%;
      width: 40%; /* Adjust image width as needed */
    }

    .box h3{
      margin-right: 5%;
      font-size: 50%;
    }
    
    .box >div >  p {
      font-size: 85%;
    }
  }

  @media screen and (max-width: 455px) {
    .book-room{
      height: 100vh;
    }
    .book-room > h1,.book-room > h3
    {
      font-size:large;
      margin-top: -20%;
     margin-bottom: 5%;
      animation: slideFromAbove 2s forwards;
     
     
    }

    .box {
        margin-bottom: -10%;
      width: 90%; /* Adjust width as needed */
       height: 15%;
    }

    .modal {
      width: 70%;
    }

    
    
    .box img {
      width: 40%; /* Adjust image width as needed */
    }

    .box > h3{
      margin-right: 3%;
      font-size: 30%;
      margin-top: 7%;
    }
    
    .box >div >  p {
      font-size: 50%;
    }
  }

  @media screen and (max-width: 290px) {
   
    .book-room{
      height: 75vh;
    }
    .book-room > h1,.book-room > h3
    {
      font-size:medium;
      margin-top: -30%;
     margin-bottom: 5%;
      animation: slideFromAbove 2s forwards;
     
     
    }

    .box {
        margin-bottom: -5%;
      width: 80%; /* Adjust width as needed */
       height: 10%;
    }

    .box img {
      width: 30%; /* Adjust image width as needed */
    }

    .box > h3{
      width: 8%;
      margin-right: 4%;
      font-size:x-smaller;
      margin-top: 7%;
      height:1vh;
      padding:5%;
    }
    
    .box >div >  p {
      font-size: 30%;
    }


    .modal {
      position: absolute;
      top: 15%; /* Adjust as needed */
     
      padding: 10px;
     animation: slideFromAbove 1s forwards ;
      /* Adjust font size for h2, h3, and p elements */
      font-size: 14px; /* Example size, adjust as needed */
    }
  
    /* Additional styles for amenities */
    .Amenities h3 {
      font-size: 14px; /* Example size, adjust as needed */
    }
  
    .Amenities ul li {
      font-size: 14px; /* Example size, adjust as needed */
    }

   

    
  }

  