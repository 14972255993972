.home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color:rgb( 247,236,224 )
}


.image-container {
  position: relative;
  width: 100%;
  height: 80vh;
  border-radius: 0;
  overflow: hidden;
  object-fit: cover;
  background: rgb(250, 250, 242); /* Fallback */
  background: rgba(250, 250, 242, 0.8)
}

.image-container img {
  width: 100%;
  display: block;
 height: 100%;
 object-fit:cover;
 
  transition: transform 0.5s ease;
  background:rgb(250, 250, 242);
  animation : slideFromLeft 2sec forwards;
  
}


.image-container h1 {
  padding: 1%;
  position: absolute;
  top: 75%;
  left: 50%;
  
   font-family:'Times New Roman', serif;
  transform: translate(-50%, -50%);
  background:black;
  color: rgb(255, 255, 255);
  font-size: 36px; /* Adjust font size as needed */
  text-align: center;
  z-index: 1; /* Ensure the text is above the image */
}



@keyframes slideFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.bullets {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.bullet {
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.bullet.active {
  background-color: white;
}


.transparent-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}

.nav-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(236, 226, 226, 0.459);
  border: none;
  border-radius: 10px;
  color: rgba(139, 137, 137, 0.495);
  font-size: 20px;
  cursor: pointer;
}

.nav-btn.prev {
  left: 0;
  background: #e7e0e0;
  color: #000;
}

.nav-btn.next {
  right: 0;
  color: #000;
  background: #e7e0e0;
}

/*

.image-container {
  position: relative;
  width: 100%; 
  height: 640px; // Set the height to 640px 
  overflow: hidden; // Hide overflow content 
}

.image-container img {
  width: 100%; /* Set the width of the image to 100% 
  height: 100%; /* Set the height of the image to 100% 
  object-fit: cover;
  object-position: 50% 50%;
}

.transparent-box {
  position: absolute; /* Position the box absolutely 
  top: 50%; /* Place it vertically in the middle 
  left: 10%; /* Position it to the left 
  transform: translateY(-50%); /* Adjust for vertical centering 
  width: 40%; /* Set the width of the box 
  height: 70%; /* Set the height of the box 
  background-color: rgba(196, 196, 196, 0.5); /* Transparent black color 
  box-shadow: 0 2px 4px rgba(217, 217, 217, 0.3); /* Add shadow effect 
  display: block;
  justify-content: center;
  align-items: center;
}


*/
.first {
  color:rgb(10,74,110);
  font-size: 70px;
  text-align: center;
  margin-left: 10px;
  margin-top: 20%;

  font-weight: 300;
}

.second {
  margin-top: -60px;
  color: rgb(10,74,110);
  font-size: 70px;
  text-align: center;
  margin-left: -100px;

  font-weight: 300;
}
.transparent-box h2 {
  margin-top: -40px;
  color:rgb(10,74,110);
  font-size: 32px;
  text-align: start;

  margin-left: 110px;
  font-weight: lighter;
}










.content {
  margin-top: 0%;
  width: 60%;
  height: 100vh;
  display: inline-flex;
  align-items: flex-start;
  text-align: center;
  background:rgb( 247,236,224 );
}

.just_content {
  color:  rgb(10,74,110);
  display: block;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 7%;
  margin-left: -1.5%;
}
.just_content h1 {
  
  font-size: 200%;
  font-weight: lighter;
  margin-bottom: 1%;
}


.roomcontainer{
  margin-left: -30%;
  display: flex;
  flex-direction: row;
  width: 150%;
  background-color:#e9d1ad;
  align-items: center;
  justify-content:space-evenly;
}

.imageslider{
  width: 25%;
  padding: 3%;
  margin-bottom: 2%;
}
.roomcontent{
  font-size: medium;
  width: 40%;
}




.just_content p {
  
  font-size: 100%;
  width: 100%;
  line-height: 200%;
  margin-bottom: 80px;
}

.just_content button {
  padding: 10px 20px;
  color: rgb(10,74,110);
  cursor: pointer;
  background-color: rgb(247, 236, 224);
  border-radius: 100px;
  font-size: larger;
  border: 1.5px solid rgb(3, 3, 3);
  transition: color 0.8s ease; /* Removed unnecessary 0s */
}

.just_content button:hover {
  color: rgb(247, 236, 224);
  background: rgb(51, 70, 153);
}


.midcontent {
 
  display: block;
  text-align: center;
 width: 100%;
 
}

.midcontent h1 {
 margin-bottom: -5%;
  font-size: 200%;
  font-weight: lighter;
  color: rgb(10, 74, 110);
}

.midcontent p {
  font-family: 'open sans', sans-serif;
  font-size: 5%;
  color: rgb(10, 74, 110);
  line-height: 1.875em;
}

.image-wrapper {
  display:inline-block;
  text-align: center;

  margin-top: 10%;
  margin-bottom: 5%;
  margin-left: 10%;
  position: relative;
}

.small-image {
  width: 200px;

  transform: scale(0.8); /* Initial scale */
  transition: transform 0.8s ease; /* Smooth transition */
}

.image-wrapper:hover .small-image {
  transform: scale(1); /* Scale on hover */
}

.image-wrapper p {
  position: absolute;
  font-size: 70%;
  font-weight: bolder;
  left: 50%;
  transform: translateX(-50%);
}
.midcontent2 {
  margin-top: 5%;
  display: block;
  text-align: center;
  width: 100%;
  background-image: url('https://res.cloudinary.com/dl2zoh4zs/image/upload/v1712689171/nbdvryvqnywdblcsocqv.png');
  background-size: cover;
  background-position: center;
  filter: brightness(100%); /* Adjust the brightness level as needed */
}

.midcontent2 h1 {

  font-size: 250%;
  font-weight: lighter;
  color: rgb(255, 255, 255);
}

.midcontent2 p {
  font-family: 'open sans', sans-serif;
  font-size: 15px;
  color: rgb(251, 254, 255);
  line-height: 1.875em;
}

.midcontent2 button {
  padding: 10px 20px;
  
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0); /* Transparent background */
  border-radius: 100px;
  font-size: larger;
  border: 1.5px solid rgb(255, 255, 255);
  margin-bottom: 5%;
  transition: color 0.8s ease;
}

.midcontent2 a{
  color:white;
}

.midcontent2 button:hover {
  color: rgb(247, 236, 224);
  background: rgb(51, 70, 153);
}



.content2 {
 
  width: 100%;
  height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background:rgb( 247,236,224 );
}

.content2 img {
  
  margin-left: 4%;
  margin-top: -4%;
  width: 100%; /* Set the width of the image to 100% */
  height: 100%; /* Set the height of the image to 100% */
  object-fit: cover;
  position: relative;
  
  z-index: 1;
  
}

.just_content2 {
  color:  rgb(51, 70, 153);
  display: block;
  width:30%;
  justify-content: center;
  align-items: center;
  padding: 7%;
  margin-left: -1.5%;
}
.just_content2 h1 {
  color: rgb(10,74,110);
  font-size: 250%;
  font-weight: lighter;
  margin-bottom: -10px;
}

.just_content2 h2 {
  
 color: rgb(10,74,110);
  font-weight: lighter;
  margin-bottom: -15px;
}

.just_content2 p {
  color: rgb(10,74,110);
  padding: 2%;
  font-size: 118%;
 
  line-height: 150%;
 
}


.tt{
  margin-left: 10%;
  width: 30%; /* Set the width of the image to 100% */
  height: 65%; /* Set the height of the image to 100% */
 background-color:rgb(10,74,110) ;
  object-position: 50% 50%;
  z-index: 0;

}



@media screen and (max-width: 950px) {
 
  .imageslider{
    width: 30%;
    padding: 2%;
    margin-bottom: 5%;
  }

  .roomcontent{
   
    font-size: medium;
    width: 40%;
  }
 
}



@media screen and (max-width: 768px) {
  .image-container {
    height: 100%;
  }

  .image-container h1 {
    font-size: 28px; /* Adjust font size for smaller screens */
  }
  .nav-btn {
    font-size: 16px;
  }

  .imageslider{
    width: 30%;
    padding: 2%;
    margin-bottom: 5%;
  }

  .roomcontent{
    margin-top:6%;
    font-size: small;
    width: 40%;
  }
 
}



@media screen and (max-width: 768px) {
  .image-container {
    height: 400px; /* Adjust height for smaller screens */
  }
}

@media screen and (max-width: 576px) {
  .image-container {
    height: 300px; /* Further adjust height for even smaller screens */
  }

  .image-container h1 {
    font-size: 24px; /* Further adjust font size for even smaller screens */
  }

  .imageslider{
    width: 35%;
    padding: 2%;
    margin-bottom: 5%;
  }

  .roomcontent{
    margin-top: 10%;
    font-size: x-small;
    width: 40%;
  }

  .small-image{
    width: 150px;
 }
}



@media screen and (max-width: 480px) {
  .image-container {
    height: 280px; /* Further adjust height for even smaller screens */
  }

  .image-container h1 {
    font-size: 150%; /* Further adjust font size for even smaller screens */
    top: 70%; /* Adjust the vertical position of the h1 */
  }

  .bullets {
    bottom: 5%; /* Adjust bullet position for smaller screens */
  }

  .imageslider{
    width: 30%;
    padding: 2%;
    margin-bottom: 5%;
  }

  .roomcontent{
    margin-top: 10%;
    font-size: x-small;
    width: 40%;
  }

  .nav-btn {
    font-size: 14px;
  }
  .small-image{
     width: 150px;
  }
}

@media screen and (max-width: 390px) {
  .image-container {
    height: 240px; /* Further adjust height for the smallest screens */
  }

  .image-container h1 {
    font-size: 100%; /* Further adjust font size for the smallest screens */
    top: 65%; /* Adjust the vertical position of the h1 */
  }

  .bullets {
    margin-bottom: 5%; /* Adjust bullet position for smaller screens */
  }

  .nav-btn {
    font-size: 14px;
  }

  .roomcontainer{
    margin-left: -30%;
    display: flex;
    flex-direction: row;
    width: 150%;
    background-color:#e9d1ad;
    align-items: center;
    justify-content:space-evenly;
  }

  .roomcontainer {
  display: flex;
}

.imageslider {
  width: 50%;
}

.slider {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}

.slider img {
  scroll-snap-align: start;
  width: 100%;
  height: auto;
}

 
  .imageslider{
    width: 40%;
    padding: 3%;
    margin-bottom: 2%;
    height: 10%;
  }
  .roomcontent{
    width: 50%;
    margin-top: 20%;
    font-size: 8px;
  }

   .small-image{
    margin-top: 5%;
    width: 150px;
   }
  .midcontent{
    margin-bottom: 10%;
  }
}

@media screen and (max-width:300px){

.roomcontent{
  font-size: 6px;
  width: 50%;
}


  
}

@media screen and (max-width:200px){

  .roomcontent{
    font-size: 4px;
    width: 50%;
  }
  
  
    
  }
  

